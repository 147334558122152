<template>
    <div class="bg-white shadow-md rounded-md p-4 m-4 relative">
      <p>Company: {{ intel.company_name }}</p>
      <p>Crew: {{ intel.crew_name }}</p>
      <p>Posted: {{ intel.created_at }}</p>
      <button class="absolute top-3 right-4" type="button" @click="deleteIntel">&#x2715;</button>
    </div>
</template>

<script>
export default {
  name: 'IntelItem',
  props: {
    intel: {
      type: Object
    }
  },
  methods: {
    deleteIntel: function() {
      console.log(`Component trying to delete ${JSON.stringify(this.intel)}`)
      this.$store.dispatch('deleteIntel', this.intel)
      this.$store.dispatch('decrementUserPoints')
    }
  }
}
</script>