<template>
  <div class="mx-auto md:w-1/2">
    <div v-if="userName">
      <CreateIntel />
      <ListIntelItems />
    </div>
    <div v-else>
      <h1 class="text-5xl text-center my-8 text-green-900">Welcome to Crowd Scout.</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import CreateIntel from "@/components/CreateIntel.vue"
import ListIntelItems from "@/components/ListIntelItems.vue"

export default {
  name: 'Home',
  computed: mapState({
    userName: state => state.account.userName
  }),
  components: {
    CreateIntel,
    ListIntelItems
  }
}
</script>
