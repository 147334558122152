import api from '@/common/api'

const state = {
    companies: null,
    crews: null,
    intel: null
  };
    
  const mutations = {
    SET_COMPANIES (state, payload) {
      state.companies = payload
    },
    SET_CREWS (state, payload) {
      state.crews = payload
    },
    SET_INTEL (state, payload) {
      state.intel = payload
    },
    DELETE_INTEL (state, payload) {
      let index = undefined
      for (const [idx, intel] of state.intel.entries()) {
        if (intel.id === payload.id) {
          index = idx
          break
        }
      }
      state.intel.splice(index, 1)
    },
    ADD_INTEL (state, payload) {
      state.intel.unshift(payload)
    }
  };

  const actions = {
    getIntel ({ commit }) {
      api.getIntel()
        .then(resp => {
          commit('SET_INTEL', resp.data)
        })
    },
    addIntel ({ commit }, payload) {
      commit('ADD_INTEL', payload)
    },
    deleteIntel ({ commit }, payload) {
      console.log(`Action tring to delete ${JSON.stringify(payload)}`)
      api.deleteIntel(payload.id)
        .then(resp => {
          console.log(resp.data)
          commit('DELETE_INTEL', payload)
        })
    }
  };
  
  const getters = {
    getCompanies(state) {
      return state.companies
    },
    getCrews(state) {
      return state.crews
    },
    getIntel(state) {
      return state.intel
    }
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  }