import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Reset from '@/views/Reset.vue'
import ResetConfirm from '@/views/ResetConfirm.vue'
import VerifyConfirm from '@/views/VerifyConfirm.vue'
import Contact from '@/views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/reset/confirm/:uid/:token',
    name: 'ResetConfirm',
    component: ResetConfirm
  },
  {
    path: '/verify/confirm/:key',
    name: 'VerifyConfirm',
    component: VerifyConfirm
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
