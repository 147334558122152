const state = {
  userEmail: null,
  userId: null,
  userName: null,
  userToken: null,
  userPoints: null
};
  
const mutations = {
  SET_USEREMAIL (state, payload) {
    state.userEmail = payload
  },
  SET_USERID (state, payload) {
    state.userId = payload
  },
  SET_USERNAME (state, payload) {
    state.userName = payload
  },
  SET_USERTOKEN (state, payload) {
    state.userToken = payload
  },
  SET_USERPOINTS (state, payload) {
    state.userPoints = payload
  },
  INCREMENT_USERPOINTS (state) {
    state.userPoints += 1
  },
  DECREMENT_USERPOINTS (state) {
    state.userPoints -= 1
  }
};

const getters = {
  getUserId (state) {
    return state.userId
  },
  getUserName (state) {
    return state.userName
  },
  getUserToken (state) {
    return state.userToken
  },
  getUserPoints (state) {
    return state.userPoints
  }
};

const actions = {
  incrementUserPoints ({ commit }) {
    commit('INCREMENT_USERPOINTS')
  },
  decrementUserPoints ({ commit }) {
    if (state.userPoints > 0) {
      commit('DECREMENT_USERPOINTS')
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}