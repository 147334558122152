<template>
  <div class="mx-auto md:w-1/2">
    <LoginCard />
  </div>
</template>

<script>
import LoginCard from "@/components/LoginCard"

export default {
  name: 'Login',
  components: {
    LoginCard
  }
}
</script>