<template>
  <nav class="font-sans flex flex-col text-center sm:flex-row sm:text-left sm:justify-between py-4 px-6 bg-white shadow sm:items-baseline w-full">
    <div class="mb-2 sm:mb-0">
      <span class="text-2xl no-underline">
        <router-link class="hover:text-green-700" to="/">Crowd Scout <span class="text-sm">(beta)</span></router-link>
      </span>
    </div>
    <div v-if="userName">
      <Menu as="div" class="relative inline-block text-left">
        <div v-if="userPoints">
          <MenuButton class="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-green-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
            <span class="text-xl">{{ userName }}</span>
            <!-- <span class="rounded-full bg-green-500 text-white mx-2 px-2">{{ userPoints }}</span> -->
            <span class="text-gray-700 ml-4">&#9660;</span>
          </MenuButton>
        </div>
        <div v-else>
          <MenuButton class="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-green-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
            <span class="text-xl">{{ userName }}</span>
            <span class="text-gray-700 ml-2">&#9660;</span>
          </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <form method="POST" autocomplete="on" v-on:submit.prevent="logout">
                <MenuItem v-slot="{ active }">
                  <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm w-full text-left']" type="submit">Sign out</button>
                </MenuItem>
              </form>
              <MenuItem v-slot="{ active }">
                <router-link :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm w-full text-left']" to="/contact">Contact us</router-link>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div v-else>
      <span class="text-lg ml-2">
        <router-link class="hover:text-green-700" to="/login">Login</router-link>
      </span>
      <span class="text-lg ml-2">
        <router-link class="hover:text-green-700" to="/register">Register</router-link>
      </span>
    </div>
  </nav>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { mapState } from "vuex"

export default {
  name: 'NavBar',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  computed: mapState({
    userName: state => state.account.userName,
    userPoints: state => state.account.userPoints
  }),
  methods: {
    logout () {
      this.$store.commit('SET_USERNAME', null)
      this.$store.commit('SET_USERTOKEN', null)
      this.$store.commit('SET_USEREMAIL', null)
      this.$store.commit('SET_USERPOINTS', null)
    }
  }
}
</script>