<template>
  <div class="mx-auto md:w-1/2">
    <VerifyConfirmCard />
  </div>
</template>

<script>
import VerifyConfirmCard from '@/components/VerifyConfirmCard.vue'
export default {
  name: 'VerifyConfirm',
  components: {
    VerifyConfirmCard
  }
}
</script>