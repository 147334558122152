// import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import intel from './modules/intel'
import createPersistedState from "vuex-persistedstate";

// Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    intel
  },
  plugins: [
    createPersistedState()
  ]
});

export default store