<template>
  <div class="m-4">
    <form class="bg-white shadow-md rounded-md p-4">
      <div class="mb-4">
        <p class="text-lg font-bold">Week of {{ getSunday() }}</p>
      </div>
      <div class="mb-4 grid grid-rows-2 grid-flow-col">
        <label class="block">
          Company
        </label>
        <div class="flex" v-if="verifiedCompany">
          <select v-model="selectedCompany" class="h-10 p-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full">
            <option v-for="option in computedCompanies" v-bind:value="option.id" v-bind:key="option.id">
              {{ option.name }}
            </option>
          </select>
          <button class="flex-none rounded text-white bg-green-500 hover:bg-green-700 ml-2 w-24" type="button" @click="verifiedCompany = !verifiedCompany">Add</button>
        </div>
        <div class="flex" v-else>
          <input class="h-10 p-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 flex-grow" v-model="newCompany" type="text">
          <button class="flex-none rounded text-white bg-green-500 hover:bg-green-700 ml-2 w-24" type="button" @click="verifiedCompany = !verifiedCompany">Discard</button>
        </div>
      </div>
      <div class="mb-6 grid grid-rows-2 grid-flow-col">
        <label class="block">
          Crew
        </label>
        <div class="flex" v-if="verifiedCrew">
          <select v-model="selectedCrew" class="h-10 p-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full">
            <option v-for="option in computedCrews" v-bind:value="option.id" v-bind:key="option.id">
              {{ option.name }}
            </option>
          </select>
          <button class="flex-none rounded text-white bg-green-500 hover:bg-green-700 ml-2 w-24" type="button" @click="verifiedCrew = !verifiedCrew">Add</button>
        </div>
        <div class="flex" v-else>
          <input class="h-10 p-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 flex-grow" v-model="newCrew" type="text">
          <button class="flex-none rounded text-white bg-green-500 hover:bg-green-700 ml-2 w-24" type="button" @click="verifiedCrew = !verifiedCrew">Discard</button>
        </div>
      </div>    
      <div class="">
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold h-10 w-full rounded focus:outline-none focus:shadow-outline" type="button" @click="submit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/common/api'
import { mapState } from "vuex"

function postIntel (user, company, crew) {
  api.postIntel(user, company, crew)
    .then(resp => {
      this.$store.dispatch('addIntel', resp.data)
      this.$store.dispatch('incrementUserPoints')
    })
    .catch(err => {
      console.log(JSON.stringify(err))
    })
}

export default {
  name: 'CreateIntel',
  data () {
    return {
      companyOptions: [],
      crewOptions: [],
      verifiedCompany: true,
      verifiedCrew: true,
      selectedCompany: null,
      selectedCrew: null,
      newCompany: null,
      newCrew: null
    }
  },
  computed: mapState({
    computedCompanies: state => state.intel.companies,
    computedCrews: state => state.intel.crews
  }),
  methods: {
    submit () {
      const user = this.$store.getters['getUserId']
      let company
      let crew
      if (this.verifiedCompany && this.verifiedCrew) {
        company = this.selectedCompany
        crew = this.selectedCrew
        postIntel(user, company, crew)
      } else if (this.verifiedCompany) {
        company = this.selectedCompany
        api.addCrew(this.newCrew)
          .then(resp => {
            crew = resp.data.id
            postIntel(user, company, crew)
          })
      } else if (this.verifiedCrew) {
        crew = this.selectedCrew
        api.addCompany(this.newCompany)
          .then(resp => {
            company = resp.data.id
            postIntel(user, company, crew)
          })
      } else {
        api.addCompany(this.newCompany)
          .then(resp => {
            company = resp.data.id
            api.addCrew(this.newCrew)
              .then(resp => {
                crew = resp.data.id
                postIntel(user, company, crew)
              })
          })
      }
    },
    getSunday () {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth()
      let sunday = new Date(year, month, today.getDate() - today.getDay())
      sunday = sunday.toLocaleDateString()
      return sunday
    }
  },
  created () {
    api.getCompanies()
      .then(resp => {
        const data = resp.data.filter(it => it.verified)
        this.$store.commit('SET_COMPANIES', data)
      })
    api.getCrews()
      .then(resp => {
        const data = resp.data.filter(it => it.verified)
        this.$store.commit('SET_CREWS', data)
      })
  }
}
</script>