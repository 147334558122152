<template>
  <div>
    <div class="bg-red-100 border-2 border-red-300 text-red-500 rounded-md p-4 m-4" v-if="loginError">
      <p>There was a problem logging in.</p>
      <ul class="list-disc list-inside">
        <li>Check your email, and validate your registration.</li>
        <li>Check that you are using the correct email and/or password.</li>
      </ul>
    </div>
    <form class="bg-white shadow-md rounded-md p-4 m-4" method="POST" autocomplete="on" v-on:submit.prevent="login">
      <div class="mb-4">
        <label class="block">Email</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" type="email" name="email" v-model="form.email">
      </div>
      <div class="mb-6">
        <label class="block">Password</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" type="password" v-model="form.password">
        <router-link class="hover:text-green-700" to="/reset">Forgot password</router-link>
      </div>
      <div class="flex flex-row-reverse">
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from "@/common/auth"

export default {
  name: 'LoginCard',
  data () {
    return {
      loginError: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login () {
      console.log("Trying login.")
      auth.getToken(this.form)
        .then(resp => {
          const token = `Token ${resp.data.key}`
          console.log(`Got token ${token}`)
          this.$store.commit('SET_USERTOKEN', token)
          auth.getUser()
            .then(resp => {
              console.log(resp.data)
              this.$store.commit('SET_USERNAME', resp.data.username)
              this.$store.commit('SET_USEREMAIL', resp.data.email)
              this.$store.commit('SET_USERID', resp.data.id)
              this.$store.commit('SET_USERPOINTS', resp.data.points)
              this.$router.push({name: 'Home'})
            })
            .catch(err => {
              console.log('There was an error getting the user:', err.response)
            })
        })
        .catch(err => {
          this.loginError = true
          console.log('There was an error getting the user token:', err.response)
        })
    }
  }
}
</script>