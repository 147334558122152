<template>
  <div id="app">
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/layout/NavBar.vue"
import Footer from "@/components/layout/Footer.vue"

export default {
  name: "App",
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
</style>
