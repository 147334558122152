import {HTTP} from '@/common/http'
import store from '@/store'

function headers () {
  const token = store.getters['getUserToken']
  const headers = {
    "Authorization": token
  }
  return headers
}

function getToken (form) {
  return HTTP.post('/api/v1/auth/login/', form)
}

function getUser () {
  return HTTP.get('/api/v1/auth/user/', {headers: headers()})
}

function logout () {
  return HTTP.post('/api/v1/auth/logout/', {}, {headers: headers()})
}

function signup (form) {
  return HTTP.post('/api/v1/auth/users/', form)
}

function register (form) {
  return HTTP.post('/api/v1/auth/register/', form)
}

function reset (form) {
  return HTTP.post('/api/v1/auth/password/reset/', form)
}

function resetConfirm (form) {
  return HTTP.post('/api/v1/auth/password/reset/confirm/', form)
}

function verifyConfirm (data) {
  return HTTP.post('api/v1/auth/register/verify-email/', data)
}

export default {
  headers,
  getToken,
  getUser,
  logout,
  register,
  signup,
  reset,
  resetConfirm,
  verifyConfirm
}
