<template>
  <div class="mx-auto md:w-1/2">
    <RegisterCard />
  </div>
</template>

<script>
import RegisterCard from "@/components/RegisterCard"

export default {
  name: 'Register',
  components: {
    RegisterCard
  }
}
</script>