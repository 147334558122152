<template>
  <div>
    <div v-for="intel in computedIntel" :key=intel.id>
      <IntelItem :intel="intel" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import IntelItem from '@/components/IntelItem.vue'

export default {
  name: 'ListIntelItems',
  data () {
    return {
      intel: null,
    }
  },
  components: {
    IntelItem
  },
  computed: mapState({
    computedIntel: state => state.intel.intel
  }),
  created () {
    this.$store.dispatch('getIntel')
  }
}
</script>