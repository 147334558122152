<template>
  <footer>
    <div class="text-center text-green-900 pt-8 mt-4">
      Copyright &copy; <a href="https://miro.solutions">Miro Solutions LLC</a> 2021 
    </div>
    <div class="text-center text-green-900 pb-8">
      <a target="_blank" href="https://icons8.com/icon/63575/pirate">Pirate</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>