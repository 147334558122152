<template>
  <div>
    <div class="bg-red-100 border-2 border-red-300 rounded-md p-4 m-4" v-if="verifyConfirmError">
      <p class="text-red-500">There was a problem completing your email verification.</p>
    </div>
  </div>
</template>

<script>
import auth from "@/common/auth"

export default {
  name: 'VerifyConfirmCard',
  data () {
    return {
      verifyConfirmError: false,
      key: this.$route.params.key
    }
  },
  mounted () {
    this.verifyConfirm()
  },
  methods: {
    verifyConfirm () {
      const data = {key: this.key}
      console.log(`Trying to verify confirm...${JSON.stringify(data)}`)
      auth.verifyConfirm(data)
        .then(resp => {
          console.log(`Verify confirm success ${JSON.stringify(resp)}`)
          this.$router.push({name: 'Home'})
        })
        .catch(err => {
          console.log('There was an error doing the verification:', err)
          this.verifyConfirmError = true
        })
    }
  }
}
</script>