<template>
  <div class="mx-auto md:w-1/2">
    <ResetConfirmCard />
  </div>
</template>

<script>
import ResetConfirmCard from "@/components/ResetConfirmCard"

export default {
  name: 'ResetConfirm',
  components: {
    ResetConfirmCard
  }
}
</script>