import {HTTP} from '@/common/http'
import auth from '@/common/auth'

function getCompanies () {
  return HTTP.get('/api/v1/company/list/', {headers: auth.headers()})
}

function getCrews () {
  return HTTP.get('/api/v1/crew/list/', {headers: auth.headers()})
}

function getIntel () {
  return HTTP.get('/api/v1/intel/list/', {headers: auth.headers()})
}

function deleteIntel (item) {
  return HTTP.delete(`/api/v1/intel/${item}/`, {headers: auth.headers()})
}

function addCompany (name) {
  const data = {
    name: name,
    verified: false
  }
  return HTTP.post(`/api/v1/company/create/`, data, {headers: auth.headers()})
}

function addCrew (name) {
  const data = {
    name: name,
    verified: false
  }
  return HTTP.post(`/api/v1/crew/create/`, data, {headers: auth.headers()})
}

function postIntel (user, company, crew) {
  const data = {
    user: user,
    company: company,
    crew: crew
  }
  return HTTP.post('/api/v1/intel/create/', data, {headers: auth.headers()})
}

export default {
  getCompanies,
  getCrews,
  getIntel,
  postIntel,
  deleteIntel,
  addCompany,
  addCrew
}