<template>
  <div>
    <div class="bg-red-100 border-2 border-red-300 rounded-md p-4 m-4" v-if="resetError">
      <p class="text-red-500">There was a problem starting your password reset.</p>
    </div>
    <form class="bg-white shadow-md rounded-md p-4 m-4" method="POST" autocomplete="on" v-on:submit.prevent="reset">
      <div class="mb-4">
        <label class="block">Email</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" type="email" name="email" v-model="form.email">
      </div>
      <div class="flex flex-row-reverse">
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from "@/common/auth"

export default {
  name: 'ResetCard',
  data () {
    return {
      resetError: false,
      form: {
        email: '',
      }
    }
  },
  methods: {
    reset () {
      console.log("Trying password reset...")
      auth.reset(this.form)
        .then(resp => {
          console.log(`Password reset response...${JSON.stringify(resp)}`)
        })
        .catch(err => {
          this.resetError = true
          console.log('There was an error doing the password reset:', err.response)
        })
    }
  }
}
</script>