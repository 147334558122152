<template>
  <div class="mx-auto md:w-1/2">
    <ResetCard />
  </div>
</template>

<script>
import ResetCard from "@/components/ResetCard"

export default {
  name: 'Reset',
  components: {
    ResetCard
  }
}
</script>