<template>
  <div>
    <div class="bg-red-100 border-2 border-red-300 rounded-md p-4 m-4" v-if="registerError">
      <p class="text-red-500">There was a problem registering. Try another username or email.</p>
    </div>
    <form class="bg-white shadow-md rounded-md p-4 m-4" method="POST" autocomplete="on" v-on:submit.prevent="register">
      <div class="mb-4">
        <label class="block">Username</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" v-model="form.username" type="text">
      </div>
      <div class="mb-4">
        <label for="email" class="block">Email</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" type="email" name="email" v-model="form.email">
      </div>
      <div class="mb-4">
        <label class="block">Password</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" type="password" v-model="form.password1">
      </div>
      <div class="mb-6">
        <label class="block">Password (again)</label>
        <input class="p-2 my-2 border rounded outline-none focus:outline-none focus:ring-2 focus:ring-green-500 w-full" type="password" v-model="form.password2">
      </div>
      <div class="flex flex-row-reverse">
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from "@/common/auth"

export default {
  name: 'RegisterCard',
  data () {
    return {
      registerError: false,
      form: {
        username: '',
        email: '',
        password1: '',
        password2: ''
      }
    }
  },
  methods: {
    register () {
      console.log("Trying to register.")
      auth.register(this.form)
        .then(resp => {
          console.log(`Register success ${resp}`)
          this.$router.push({name: 'Home'})
        })
        .catch(err => {
          console.log('There was an error getting the user:', err)
          this.registerError = true
        })
    }
  }
}
</script>